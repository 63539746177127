import React from "react";

interface BorderProps {
  className: string;
  children: React.ReactNode;
  height?: string;
}

export const BorderedContainer: React.FC<BorderProps> = ({
  className,
  children,
  height,
}) => {
  return (
    <div
      className={`bg-slate-800 p-2 lg:p-5 border-neutral-950 border-2 rounded-md shadow-lg ${className}`}
    >
      <div
        style={{ height: !!height ? height : undefined }}
        className="md:px-10 px-2 py-4 md:py-8  bg-gray-900 rounded-lg"
      >
        {children}
      </div>
    </div>
  );
};
